import { IRoseAGGridColumn } from '@rose/common-ui';
import { IBehandler, ITerminart } from '@rose/types';
import { IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function settingsAbrechnungTerminBehandlerColumnsDefs(): IRoseAGGridColumn<IBehandler>[] {
  return [
    {
      headerName: 'Behandler',
      valueGetter: params => params.data,
      comparator: (a: IBehandler, b: IBehandler) => a.name.localeCompare(b.name),
      cellRenderer: 'BehandlerCell',
      sort: 'asc',
      sortable: true,
      width: 200,
      flex: 1,
    },
    {
      headerName: '',
      field: 'actions',
      cellRenderer: 'DeleteButtonCell',
      width: 40,
      cellStyle: { textAlign: 'center', 'justify-content': 'center' },
      cellRendererParams: {
        context: {
          clicked(field: { row: ITerminart }) {
            editSettingsStore.commit.deleteIgnorierteTerminbehandler(field.row.extid);
          },
        },
      } as IDeleteButtonCell<ITerminart>,
    },
    {
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 40,
    },
  ];
}
