import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import AbrechnungTable from '@/components/tables/settings/Abrechnung/AbrechnungTable.vue';
import AbrechnungTableTerminArten from '@/components/tables/settings/Abrechnung/AbrechnungTableTerminArten.vue';
import AbrechnungTableTerminBehandler from '@/components/tables/settings/Abrechnung/AbrechnungTableTerminBehandler.vue';
import { settingsAbrechnungTerminArtenColumnsDefs } from '@/components/columns/settings/abrechnungTerminArten';
import { settingsAbrechnungTerminBehandlerColumnsDefs } from '@/components/columns/settings/abrechnungTerminBehandler';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    AbrechnungTable: AbrechnungTable,
    AbrechnungTableTerminArten: AbrechnungTableTerminArten,
    AbrechnungTableTerminBehandler: AbrechnungTableTerminBehandler
  },
  data: function data() {
    return {
      columnArtenDefs: settingsAbrechnungTerminArtenColumnsDefs(),
      columnBehandlerDefs: settingsAbrechnungTerminBehandlerColumnsDefs()
    };
  },
  computed: {
    regelGroupedByCategory: function regelGroupedByCategory() {
      return editSettingsStore.getters.regelGroupedByCategory;
    },
    ignoriereTerminArten: function ignoriereTerminArten() {
      return editSettingsStore.getters.ignoriereTerminArten;
    },
    ignoriereTerminBehandler: function ignoriereTerminBehandler() {
      return editSettingsStore.getters.ignoriereTerminBehandler;
    },
    availableTerminArten: function availableTerminArten() {
      return editSettingsStore.getters.availableTerminArten;
    },
    availableTerminBehandler: function availableTerminBehandler() {
      return editSettingsStore.getters.availableTerminBehandler;
    }
  },
  methods: {
    addTerminArten: function addTerminArten(items) {
      editSettingsStore.commit.addAbrechnungIgnoriereTerminArten(items);
    },
    removeTerminArten: function removeTerminArten(selected) {
      var items = selected.map(function (v) {
        return v.extid.toLowerCase();
      });
      editSettingsStore.commit.removeAbrechnungIgnoriereTerminArten(items);
    },
    addTerminBehandler: function addTerminBehandler(items) {
      editSettingsStore.commit.addAbrechnungIgnoriereTerminBehandler(items);
    },
    deleteTerminBehandler: function deleteTerminBehandler(selected) {
      var items = selected.map(function (v) {
        return v.extid.toLowerCase();
      });
      editSettingsStore.commit.removeAbrechnungIgnoriereTerminBehandler(items);
    }
  }
});